import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabelProps,
  RadioGroup,
  RadioGroupProps,
} from "@mui/material";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import Radio, { RadioProps } from "@mui/material/Radio";
import {
  getAriaDescribedByValue,
  getKey,
} from "@recare/core/model/utils/strings";
import { EitherOr } from "@recare/core/types";
import {
  FormElementRenderProps,
  FormWatcher,
  isValid,
} from "@recare/react-forms-state";
import { useTranslations } from "@recare/translations";
import { padding } from "ds/materials/metrics";
import { FONT_SIZE_14 } from "ds/materials/typography";
import { Fragment } from "react";
import { SubheadingFormLabel } from "../FormComponents/SubheadingFormLabel";
import { getHelperText } from "../Validation";

export type RadioOptionV2 = EitherOr<
  { label: string },
  { ariaLabel: string },
  {
    disabled?: boolean;
    id: number | string;
    subForm?: React.ReactNode;
    value: number;
  }
>;

export type RadioGroupV2Props = {
  defaultValue?: RadioGroupProps["defaultValue"];
  elementName: string;
  flatModel?: boolean;
  formControlLabelSx?: FormControlLabelProps["sx"];
  formControlSx?: FormControlProps["sx"];
  formHelperTextSx?: FormHelperTextProps["sx"];
  formLabelSx?: FormLabelProps["sx"];
  include?: number[] | null;
  label: string;
  options: Array<RadioOptionV2>;
  radioGroupSx?: RadioGroupProps["sx"];
  radioSx?: RadioProps["sx"];
  required?: boolean;
  row?: RadioGroupProps["row"];
  sideMutation?:
    | ((
        newValue: any,
        mutateElement: (value: any, elementName: string) => void,
      ) => void)
    | undefined;
};

export default function RadioGroupV2({
  defaultValue,
  elementName,
  flatModel,
  formControlLabelSx,
  formControlSx,
  formHelperTextSx,
  formLabelSx,
  include,
  label,
  options,
  radioGroupSx,
  radioSx,
  required,
  row,
  sideMutation,
}: RadioGroupV2Props) {
  const translations = useTranslations();
  const legendId = `${elementName}-radio-group-legend`;
  let optionsToShow = options;

  if (include) {
    optionsToShow = options.filter((option) => include.includes(option?.value));
  }

  return (
    <FormElementRenderProps
      flatModel={flatModel}
      elementName={elementName}
      sideMutation={sideMutation}
    >
      {({ onChange, validation, value }) => {
        const errorTextId = `${elementName}_error_text_id`;
        const hasError = !isValid(validation);
        return (
          <FormControl
            error={hasError}
            sx={{ ...formControlSx }}
            component="fieldset"
          >
            <SubheadingFormLabel
              id={legendId}
              required={required}
              sx={formLabelSx}
            >
              {label}
            </SubheadingFormLabel>
            <RadioGroup
              aria-labelledby={`${legendId} ${
                hasError ? errorTextId : ""
              }`.trim()}
              defaultValue={defaultValue}
              name={elementName}
              onChange={(e, value) => onChange(Number(value))}
              row={row}
              sx={{ ...radioGroupSx }}
              value={value}
            >
              {optionsToShow.map(({ subForm, ...option }, index) => (
                <Fragment key={getKey("fragment", option.value, index)}>
                  <FormControlLabel
                    control={
                      <Radio
                        sx={radioSx}
                        inputProps={{
                          ...{
                            ["data-testid"]: `radio_${elementName}_${option.value}`,
                          },
                          "aria-label": option.ariaLabel,
                          "aria-invalid": hasError ? true : undefined,
                          "aria-describedby": getAriaDescribedByValue([
                            hasError && errorTextId,
                          ]),
                        }}
                      />
                    }
                    data-testid={`radio_${elementName}_${option.value}_label`}
                    disabled={option.disabled}
                    key={getKey("controlLabel", option.value, index)}
                    label={option.ariaLabel ? undefined : option.label}
                    required={required}
                    sx={{
                      ...formControlLabelSx,
                      "& .MuiFormControlLabel-label": {
                        fontSize: FONT_SIZE_14,
                        padding: padding(0, 0, 0, 1),
                      },
                      "& .MuiFormControlLabel-asterisk": {
                        display: "none",
                      },
                    }}
                    value={option.value}
                  />
                  {subForm && (
                    <FormWatcher
                      watchPath={elementName}
                      key={getKey("subForm", option.value, index)}
                    >
                      {({ watchedValue }) => {
                        const isSelected = watchedValue === option.value;
                        return (
                          <div
                            style={{ display: isSelected ? "block" : "none" }}
                          >
                            {subForm}
                          </div>
                        );
                      }}
                    </FormWatcher>
                  )}
                </Fragment>
              ))}
            </RadioGroup>
            <FormHelperText
              data-testid={`${elementName}-form-helper-text`}
              error={!isValid(validation)}
              id={errorTextId}
              sx={{ ...formHelperTextSx }}
            >
              {getHelperText({
                hasCustomValidation: true,
                translations,
                validation,
              })}
            </FormHelperText>
          </FormControl>
        );
      }}
    </FormElementRenderProps>
  );
}
